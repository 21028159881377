import {API_URL} from '../../config';

export const deleteEvent = async (eventId: string) => {
  const res = await fetch(`${API_URL}/event/${eventId}`, {
    method: 'DELETE',
    credentials: 'include',
    mode: 'cors',
  });

  return res.json();
};
