import styled from 'styled-components';
import {FaqInterface} from '../../types/interfaces/FaqInterface';

interface Props {
  faq: FaqInterface;
  showFaq: boolean;
}

export const DetailsFaq = ({faq, showFaq}: Props) => {
  return (
    <DetailsBox showFaq={showFaq}>
      <Container>{faq.body}</Container>
    </DetailsBox>
  );
};

const DetailsBox = styled.div<{showFaq: boolean}>`
  @keyframes showFaq {
    from {
      opacity: 0;
    }
    to {
      opacity: 100%;
    }
  }

  @keyframes closeFaq {
    from {
      opacity: 100%;
    }
    to {
      opacity: 0;
    }
  }

  animation-name: ${(props) => (props.showFaq ? 'showFaq' : 'closeFaq')};
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.darkBlue};
  padding: ${(props) => props.theme.padding.lg};
  border-bottom-left-radius: ${(props) => props.theme.borderRadius.base};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.base};
`;
