import {API_URL} from '../../config';

export const getEvents = async (quantity: number) => {
  const res = await fetch(`${API_URL}/event/many/${quantity}`, {
    method: 'GET',
    // credentials: 'include',
    mode: 'cors',
  });

  return res.json();
};
