import styled from 'styled-components';
import {EventResult} from '../../types/enums/EventResult';
import {EventInterface} from '../../types/interfaces/EventInterface';
import {DetailsEvent} from './DetailsEvent';

interface Props {
  event: EventInterface;
  admin?: boolean;
}

export const Event = ({event, admin}: Props) => {
  const formatDate = (date: string) => {
    const newDate = new Date(date);
    return `${newDate.getDate()}/${
      newDate.getMonth() + 1
    }/${newDate.getFullYear()} - ${newDate.getHours()}:${String(
      newDate.getMinutes()
    ).padStart(2, '0')}`;
  };

  return (
    <>
      <Container result={event.resultType}>
        <p className="date">{formatDate(event.date)} (CET) 📅</p>
        {/* <p className="result">
          {event.resultType === EventResult.WIN && 'WIN'}
          {event.resultType === EventResult.LOSE && 'DEFEAT'}
          {event.resultType === EventResult.UNDEFINED && 'IN PROGRESS'}
        </p> */}
        <div className="utils-box">
          <p className="rate">{event.rate}</p>
        </div>
      </Container>
      <DetailsEvent event={event} admin={admin} />
    </>
  );
};

const Container = styled.div<{
  result: EventResult;
}>`
  margin-top: ${(props) => props.theme.margin.xl};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.padding.sm}
    ${(props) => props.theme.padding.base};
  background-color: ${(props) =>
    props.result === EventResult.WIN
      ? props.theme.colors.green
      : props.result === EventResult.LOSE
      ? props.theme.colors.red
      : props.theme.colors.inputBackground};
  border-radius: ${(props) => props.theme.borderRadius.m};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .result {
    font-weight: 600;
  }

  .utils-box {
    display: flex;
    align-items: center;

    .rate {
      background-color: ${(props) => props.theme.colors.darkBlue};
      padding: ${(props) => props.theme.padding.sm}
        ${(props) => props.theme.padding.base};
      border-radius: ${(props) => props.theme.borderRadius.m};
    }
  }

  @media only screen and (max-width: 600px) {
    padding-right: ${(props) => props.theme.padding.sm};

    .date {
      font-size: 0.8rem;
    }

    .utils-box {
      .rate {
        margin-right: ${(props) => props.theme.margin.sm};
        padding: ${(props) => props.theme.padding.m}
          ${(props) => props.theme.padding.sm};
      }
    }
  }
`;
