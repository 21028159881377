import {configureStore} from '@reduxjs/toolkit';
import {eventsSlice} from './features/eventsSlice';
import {userSlice} from './features/userSlice';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    events: eventsSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
