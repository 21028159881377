import {Link} from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/images/logo.png';

export const Header = () => {
  return (
    <Container>
      <Link to="/" className="logo-item">
        <Logo src={logo} alt="logo" />
      </Link>
    </Container>
  );
};

const Container = styled.header`
  margin: ${(props) => props.theme.margin.xl2}
    ${(props) => props.theme.margin.xl} 0 ${(props) => props.theme.margin.xl};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .logo-item { 
    display: flex;
    align-items: center;
  }
  }

  @media only screen and (max-width: 600px) {
    margin-top: ${(props) => props.theme.margin.xl};
    vertical-align: middle;
  }
`;

const Logo = styled.img`
  height: 2rem;
  cursor: pointer;
`;
