import styled from 'styled-components';
import {PROTIPS} from '../../data/protips.data';
import {Faq} from '../Faq/Faq';

export const Info = () => {
  return (
    <Wrapper>
      <div className="tips-box">
        <Faq key={PROTIPS[0].id} faq={PROTIPS[0]} />
      </div>
      <Container>
        <p className="contact">
          If you need to contact me, you can write an e-mail to the following
          address{' '}
          <span className="blue">
            <a href="mailto: contact@mathtipster.com" title="eMail">
              contact@mathtipster.com
            </a>
          </span>{' '}
          or contact me on instagram{' '}
          <span className="blue">
            <a
              href="https://www.instagram.com/mathtipster"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              @mathtipster
            </a>
          </span>
        </p>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.margin.xl2};

  .tips-box {
    margin: 0 ${(props) => props.theme.margin.xl2};

    @media only screen and (max-width: 800px) {
      margin-left: ${(props) => props.theme.margin.base};
      margin-right: ${(props) => props.theme.margin.base};
    }
  }
`;

const Container = styled.div`
  margin-top: ${(props) => props.theme.margin.lg};
  margin: ${(props) => props.theme.margin.xl}
    ${(props) => props.theme.margin.xl2} 0 ${(props) => props.theme.margin.xl2};
  background-color: ${(props) => props.theme.colors.lightBlue};
  padding: ${(props) => props.theme.padding.lg}
    ${(props) => props.theme.padding.xl};
  border-radius: ${(props) => props.theme.borderRadius.base};
  color: ${(props) => props.theme.colors.darkBlue};
  display: flex;
  flex-direction: column;

  .contact {
    .blue {
      color: ${(props) => props.theme.colors.blue};
    }
  }

  @media only screen and (max-width: 800px) {
    margin-left: ${(props) => props.theme.margin.base};
    margin-right: ${(props) => props.theme.margin.base};
  }

  @media only screen and (max-width: 600px) {
    padding: ${(props) => props.theme.padding.base}
      ${(props) => props.theme.padding.lg};
  }
`;
