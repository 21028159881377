import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {EventsBox} from '../components/Analysis/AnalysisBox';
import {Arrow} from '../components/Arrow';
import {Footer} from '../components/Footer';
import {Header} from '../components/Header';
import {Info as ForAdults} from '../components/Info';
import {Loader} from '../components/Loader';
import {Info} from '../components/User/Info';
import {getEvents} from '../fetch/event/getEvents';
import {RootState} from '../store';
import {setEvents} from '../store/features/eventsSlice';
import {ArrowSide} from '../types/enums/ArrowSide';

export const Types = () => {
  const {items: events} = useSelector((store: RootState) => store.events);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    (async () => {
      if (!events) {
        const {events: data} = await getEvents(15);
        dispatch(setEvents(data));
      }
      setLoader(false);
    })();
  }, []);

  return (
    <>
      <Header />
      <Info />
      {loader ? <Loader /> : <EventsBox />}
      <Arrow side={ArrowSide.LEFT} />
      <ForAdults />
      <Footer />
    </>
  );
};
