import {createSlice} from '@reduxjs/toolkit';
import {UserRoles} from '../../types/enums/UserRoles';

export interface User {
  id: string;
  role: UserRoles;
}

export type UserState = User | null | false;

const initialState: UserState = null;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return action.payload;
    },
  },
});

export const {setUser} = userSlice.actions;
