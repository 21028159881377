import {ThemeProvider} from 'styled-components';
import {ReactNode} from 'react';

const theme = {
  colors: {
    background: '#0B1016',
    lightBlue: '#A4DCFE',
    gray: '#ABABAB',
    blue: '#1D0C90',
    inputBackground: '#181A1B',
    darkBlue: '#0B1721',
    darkGray: '#7C807F',
    white: '#FFFFFF',
    green: '#00B700',
    red: '#EB0000',
    purple: '#100F2C',
    pink: '#C60059',
  },
  fontSize: {
    sm: '1rem',
    base: '1.3rem',
    lg: '2rem',
    xl: '2.5rem',
    xl2: '3rem',
  },
  padding: {
    m: '0.3rem',
    sm: '0.5rem',
    base: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    xl2: '3rem',
    xl3: '4rem',
    xl4: '5rem',
  },
  margin: {
    sm: '0.5rem',
    base: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    xl2: '3rem',
    xl3: '4rem',
    xl4: '5rem',
    xl5: '8rem',
  },
  borderRadius: {
    m: '0.7rem',
    sm: '1rem',
    base: '1.5rem',
  },
};

interface Props {
  children: ReactNode;
}

export const Theme = ({children}: Props) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
