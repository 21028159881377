import {createSlice} from '@reduxjs/toolkit';
import {EventInterface} from '../../types/interfaces/EventInterface';

interface EventsState {
  items: EventInterface[] | null;
}

const initialState: EventsState = {
  items: null,
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvents: (state, action) => {
      state.items = action.payload.map((event: any) => {
        delete event.createdAt;
        delete event.updatedAt;
        return event;
      });
    },
    setEvent: (state, action) => {
      if (state.items !== null) {
        const event = action.payload;
        delete event.createdAt;
        delete event.updatedAt;

        state.items = [event, ...state.items];
      }
    },
    updateEvent: (state, action) => {
      if (state.items !== null) {
        state.items = state.items.map((el) => {
          if (el.id === action.payload.id) {
            const event = action.payload;
            delete event.createdAt;
            delete event.updatedAt;
            return event;
          }
          return el;
        });
      }
    },
    deleteEvent: (state, action) => {
      if (state.items !== null) {
        state.items = state.items.filter(
          (event) => event.id !== action.payload.eventId
        );
      }
    },
  },
});

export const {setEvents, setEvent, updateEvent, deleteEvent} =
  eventsSlice.actions;
