import {default as ReactMarkdown} from 'react-markdown';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';
import {deleteEvent} from '../../fetch/event/deleteEvent';
import {deleteEvent as deleteEventRedux} from '../../store/features/eventsSlice';
import {EventInterface} from '../../types/interfaces/EventInterface';

interface Props {
  event: EventInterface;
  admin?: boolean;
}

export const DetailsEvent = ({event, admin}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEditLink = () => {
    navigate(`/admin/event/${event.id}`, {replace: true});
  };

  const handleDeleteLink = async () => {
    const value = window.confirm('Czy na pewno chcesz usunąć kupon?');

    if (!value) return;

    const res = await deleteEvent(event.id);
    if (res.ok) {
      dispatch(
        deleteEventRedux({
          eventId: event.id,
        })
      );
    }
  };

  return (
    <>
      <Container>
        <p className="event-title">
          {event.sport} / {event.league}
        </p>
        <div className="box-horizontal">
          <span className="bold">Event:</span>
          {event.name}
        </div>
        <div className="box-horizontal">
          <span className="bold">Prediction:</span>
          {event.type}
        </div>
        <div className="box-horizontal">
          <p className="bold">Analysis:</p>
          <div className="markdown">
            {
              <ReactMarkdown
                children={event.analysis.replace(/\n/gi, '&nbsp; \n')}
                remarkPlugins={[remarkGfm, remarkBreaks]}
              />
            }
          </div>
        </div>
      </Container>
      <Summary>
        <div className="summary-box">
          <p className="summary">
            <span className="summary-bold">Result: </span>
            {event.result ? event.result : '❔'}
          </p>
        </div>
        {admin && (
          <div className="action-btn-box">
            <button type="button" title="Edytuj" onClick={handleEditLink}>
              <i className="bx bxs-edit"></i>
            </button>
            <button
              type="button"
              className="btn-delete"
              onClick={handleDeleteLink}
              title="Usuń kupon"
            >
              <i className="bx bx-trash-alt"></i>
            </button>
          </div>
        )}
      </Summary>
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.darkBlue};
  padding: ${(props) => props.theme.padding.lg};
  border-bottom-left-radius: ${(props) => props.theme.borderRadius.m};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.m};

  .event-title {
    color: ${(props) => props.theme.colors.gray};
  }

  .bold {
    font-weight: 800;
    margin-right: ${(props) => props.theme.margin.sm};
  }

  .markdown {
    color: ${(props) => props.theme.colors.gray};

    strong {
      font-weight: 800;
    }

    ul {
      list-style-type: square;
      padding-left: ${(props) => props.theme.padding.lg};
    }
  }
`;

const Summary = styled.div`
  background-color: ${(props) => props.theme.colors.blue};
  padding: ${(props) => props.theme.padding.base}
    ${(props) => props.theme.padding.lg};
  border-bottom-left-radius: ${(props) => props.theme.borderRadius.base};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.base};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .summary {
    .summary-bold {
      font-weight: 600;
    }
  }

  .action-btn-box {
    display: flex;

    button {
      padding: ${(props) => props.theme.padding.sm};
      margin-left: ${(props) => props.theme.margin.sm};
      background-color: ${(props) => props.theme.colors.green};
      border: none;
      color: ${(props) => props.theme.colors.white};
      font-weight: 600;
      font-size: ${(props) => props.theme.fontSize.sm};
      border-radius: ${(props) => props.theme.borderRadius.m};
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .btn-delete {
      background-color: ${(props) => props.theme.colors.red};
    }
  }

  // @media only screen and (max-width: 500px) {
  //   flex-direction: column;

  //   .summary-box {
  //     align-self: flex-start;
  //     margin-bottom: ${(props) => props.theme.margin.base};
  //   }
  }
`;
