import styled from 'styled-components';
import {useState} from 'react';
import {FaqInterface} from '../../types/interfaces/FaqInterface';
import {DetailsFaq} from './DetailsFaq';

interface Props {
  faq: FaqInterface;
}

export const Faq = ({faq}: Props) => {
  const [showFaq, setShowFaq] = useState<boolean | null>(null);

  return (
    <>
      <Container showFaq={showFaq} onClick={() => setShowFaq((prev) => !prev)}>
        <div></div>
        <p className="title">{faq.title}</p>
        <div className="utils-box">
          <i className="bx bxs-chevron-down"></i>
        </div>
      </Container>
      {showFaq && <DetailsFaq faq={faq} showFaq={showFaq} />}
    </>
  );
};

const Container = styled.div<{
  showFaq: boolean | null;
}>`
  cursor: pointer;
  margin-top: ${(props) => props.theme.margin.xl};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.padding.sm}
    ${(props) => props.theme.padding.base};
  background-color: ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.darkBlue};
  border-radius: ${(props) => props.theme.borderRadius.m};
  border-bottom-left-radius: ${(props) =>
    props.showFaq ? 0 : props.theme.borderRadius.m};
  border-bottom-right-radius: ${(props) =>
    props.showFaq ? 0 : props.theme.borderRadius.m};

  .title {
    font-weight: 600;
  }

  .utils-box {
    display: flex;
    align-items: center;

    @keyframes arrowUp {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(180deg);
      }
    }

    @keyframes arrowDown {
      from {
        transform: rotate(180deg);
      }
      to {
        transform: rotate(0deg);
      }
    }

    .bx {
      font-size: ${(props) => props.theme.fontSize.xl};
      animation-name: ${(props) =>
        props.showFaq === null ? '' : props.showFaq ? 'arrowUp' : 'arrowDown'};
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
    }
  }

  @media only screen and (max-width: 600px) {
    padding-right: ${(props) => props.theme.padding.sm};
  }
`;
