import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {RootState} from '../../store';
import {Event} from './Event';

export const EventsBox = () => {
  const {items: events} = useSelector((store: RootState) => store.events);

  return (
    <Container>
      {events && events.length > 0 ? (
        events.map((event, index) => {
          return <Event key={index} event={event} />;
        })
      ) : (
        <NoCoupons>
          <p className="title">Brak analiz</p>
          <p className="body">
            Kupony pojawią się maksymalnie do godziny 12 ⏳
          </p>
        </NoCoupons>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 0 ${(props) => props.theme.margin.xl2};

  @media only screen and (max-width: 800px) {
    margin-left: ${(props) => props.theme.margin.base};
    margin-right: ${(props) => props.theme.margin.base};
  }
`;

const NoCoupons = styled.div`
  margin-top: ${(props) => props.theme.margin.lg};
  margin: ${(props) => props.theme.margin.xl2}
    ${(props) => props.theme.margin.xl2} 0 ${(props) => props.theme.margin.xl2};
  background-color: ${(props) => props.theme.colors.lightBlue};
  padding: ${(props) => props.theme.padding.lg}
    ${(props) => props.theme.padding.xl};
  border-radius: ${(props) => props.theme.borderRadius.base};
  color: ${(props) => props.theme.colors.darkBlue};
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-weight: 600;
  }
`;
