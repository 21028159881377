import {TailSpin} from 'react-loader-spinner';
import styled from 'styled-components';

export const Loader = () => {
  return (
    <Container>
      <TailSpin height="80" width="80" color="#EB0000" ariaLabel="loading" />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${(props) => props.theme.margin.xl4} 0;

  @media only screen and (max-width: 800px) {
    margin-bottom: ${(props) => props.theme.margin.xl5};
  }
`;
