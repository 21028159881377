import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import styled from 'styled-components';
import {getEvents} from './fetch/event/getEvents';
import {Types} from './pages/Types';
import {setEvents} from './store/features/eventsSlice';

export const App = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   (async () => {
  //     const {events} = await getEvents(15);
  //     dispatch(setEvents(events));
  //   })();
  // }, []);

  return (
    <Container>
      <Routes>
        <Route path={`/`} element={<Types />} />
        <Route path={`*`} element={<Navigate to="/" />} />
      </Routes>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`;
